import './App.scss';
import Header from "./components/Header/Header";
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from './components/Home/Home';
import Lettres from './components/Letttres/Lettres';
import Playlist from './components/Playlist/Playlist';
import PageTransition from "./components/PageTransition/PageTransition";
import ludovicImage from "./img/ludovic.svg";
import josetteImage from "./img/josette.svg";
import lettre1Audio from "./audio/01_30_05_1948.mp3";
import lettre2Audio from "./audio/02_02_06_1948.mp3";
import {useEffect, useState} from "react";
import Login from "./components/Login/Login";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import apiService from "./services/apiService";

function App() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');

        if (token) {
            localStorage.setItem('authToken', token);

            const url = new URL(window.location.href);
            url.searchParams.delete('token');
            window.history.replaceState(null, '', url.toString());

            navigate('/');
        }
    }, [location]);

    const startDate = new Date(1947, 0, 1); // 1er janvier 1947
    const endDate = new Date(1948, 11, 31); // 31 décembre 1948

    const addDays = (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    const randomBetween = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

    const formatDateInFrench = (date) => {
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };

    const [userSpace, setUserSpace] = useState(null);

    useEffect(() => {
        const fetchSpaces = async () => {
            const data = await apiService.getSpaces();
            setUserSpace(data[0]);
        };

        fetchSpaces();
    }, []);

    const timelineData = Array.from({ length: 70 }, (_, index) => {
        const isEven = index % 2 === 0;

        // Générer une date en ajoutant un nombre aléatoire de jours à la date de départ
        const currentDate = addDays(startDate, index * randomBetween(5, 20));

        // S'assurer que la date reste dans la plage de 1947-1948
        if (currentDate > endDate) {
            return null;
        }

        return {
            id: index + 1,
            from: isEven ? 'Ludovic Reynes' : 'Josette Corbière',
            to: isEven ? 'Josette Corbière' : 'Ludovic Reynes',
            profile_picture: isEven ? ludovicImage : josetteImage,
            title: `Lettre ${index + 1}`,
            genre: isEven ? 'man' : 'woman',
            date: formatDateInFrench(currentDate),  // Convertir la date en string lisible
            content: isEven
                ? "<p>Mademoiselle,</p><p>J'ai regagné ma retraite et ma solitude de Paulhe en proie à mes tristesses mêlées de joie pourtant car je vous ai vues et cela a réjoui mon cœur.</p><p>Depuis fort longtemps, j'aurais voulu m'entretenir avec vous, vous exposer mes idées, mes sentiments, car je crois avoir trouvé en vous l'âme sœur que je cherche pour donner un sens à ma vie.</p><p>Une timidité maladive presque, a toujours lié ma langue, et passant à côté de vous, en de rares occasions, il est vrai, je suis toujours resté muet. Que de fois ai-je voulu entreprendre une première démarche, mais les jours ont succédé aux jours sans apporter de résultat. Aujourd'hui, parce qu'ayant trop souffert, il fallait que je vous exprime par écrit ce que je n'ai encore jamais osé vous dire.</p><p>J'ignore les sentiments que vous éprouvez à mon égard, indifférence, sympathie ? Mais, par une sorte d'intuition, j'ai confiance tout de même.</p><p>Je viendrai à Saint-Pierre, jeudi, vers 3h30. Soyez sur votre seuil ou venez à ma rencontre au fond du village, pour une première entrevue. Si vous n'êtes pas là, je me dirai : la lettre ne lui est pas encore parvenue !</p><p>Peut-être ma démarche vous paraîtra-t-elle osée ou un peu ridicule et un sourire amusé rayonnera-t-il sur votre visage ! Mais pardonnez-moi, j'étais trop malheureux. Je m'en remets à votre volonté et à celle de Dieu.</p><p>Croyez, mademoiselle, à toute ma sympathie et à mes meilleurs sentiments.</p>"
                : "<p>Monsieur,</p><p>Je n'ai pas ri. Je ne me suis pas moqué de vous. J'ai été seulement étonné, troublé, très troublé. Je m'attendais si peu à cela, mais pas du tout.</p><p>Je réponds de suite, parce que demain, je me demande si j'aurai le courage de me montrer. Je vous demande cependant d'avoir confiance dans le bon Dieu. Et la vie de chacun d'entre nous lui appartient. Ces deux choses sont inséparables. Et moi, comme vous, je m'en remets à sa volonté.</p><p>En toute simplicité, je dois vous dire que jusqu'à présent, vous ne m'étiez pas tout à fait indifférent. Sympathique sans doute, en tout cas, je vous estimais. Mais vous restiez, pour moi, une énigme qui m'intriguait. Je découvrais un peu votre âme à travers votre lettre. Je sentais que vous viviez pour un bel idéal…</p><p>J'ai montré ce soir votre lettre à Paulette. Vous n'avez sans doute pas idée de qui je veux parler. Toutes les jeunes filles sont des compagnes, mais Paulette est une amie vraie. Vous ne m'en voudrez pas, mais je ne pouvais rester d’avantage, d'ailleurs, elle savait… Son fiancé vient dimanche. Elle vous propose de vous rendre chez elle après vêpres et à mon tour, je viendrai vous rejoindre. Nous serons beaucoup moins gênés que chez moi, si vous êtes timide. Je crois que je le suis aussi !!!</p><p>Quand vous recevrez cette lettre, vous aurez peut-être fui, entre la déception de ne pas m'apercevoir jeudi, je vous prie de m'excuser. Comprenez combien tout ceci a été surprenant pour moi et si inattendu. Je vous attends pour dimanche, et Paulette est si heureuse de vous recevoir.</p><p>En attendant, je vous demande d'accepter mon meilleur souvenir.</p>",
            audio: isEven ? lettre1Audio : lettre2Audio
        };
    }).filter(item => item !== null);

    return (
        <div id="app">
            <Header />
            <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                    <Route path="/connexion" element={<PageTransition><Login/></PageTransition>} />
                    <Route path="/" element={<PageTransition><ProtectedRoute><Home userSpace={userSpace} /></ProtectedRoute></PageTransition>} />
                    <Route path="/lettres" element={<PageTransition><ProtectedRoute><Lettres userSpace={userSpace} /></ProtectedRoute></PageTransition>} />
                    <Route path="/playlist" element={<PageTransition><ProtectedRoute><Playlist userSpace={userSpace} /></ProtectedRoute></PageTransition>} />
                </Routes>
            </AnimatePresence>
        </div>
    );
}

export default function AppWithRouter() {
    return (
        <Router>
            <App />
        </Router>
    );
}
