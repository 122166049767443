// src/pages/Lettres.js
import React, {useEffect, useRef, useState} from 'react';
import iconFrom from "../../img/icon-from.svg";
import iconTo from "../../img/icon-to.svg";
import iconPlay from "../../img/icon-play.svg";
import iconPause from "../../img/icon-pause.svg";
import iconVoir from "../../img/icon-voir.svg";
import H5AudioPlayer from "react-h5-audio-player";
import './Lettres.scss';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {useGSAP} from "@gsap/react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';

function Lettres({ userSpace }) {

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    useEffect(() => {
        if (prevRef.current && nextRef.current) {
            const swiper = document.querySelector('.swiper').swiper;
            if (swiper) {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
            }
        }
    }, [prevRef, nextRef]);

    gsap.registerPlugin(useGSAP);
    gsap.registerPlugin(ScrollTrigger);

    const lettreInfo = useRef(null);

    useGSAP(() => {
            if (lettreInfo.current) {
                gsap.to(lettreInfo.current, {
                    scrollTrigger: {
                        trigger: lettreInfo.current,
                        start: "top top",
                        end: "100000vh",
                        scrub: 1,
                        pin: true,
                        pinSpacing: false
                    }
                });
            }
        }, [ lettreInfo, ]
    );

    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if (userSpace && userSpace.acf && userSpace.acf.documents && userSpace.acf.documents.length > 0) {
            if (selectedItem === null) {
                setSelectedItem(userSpace.acf.documents[0]);
            }
        }
    }, [selectedItem, userSpace]);

    const handleClick = (item, index) => {
        console.log(item);
        setSelectedItem(item);
        setSelectedItemIndex(index);
    };

    const handleNextClick = () => {
        const nextIndex = selectedItemIndex + 1
        if (userSpace.acf.documents[nextIndex]) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTimeout(() => {
                setSelectedItem(userSpace.acf.documents[nextIndex]);
                setSelectedItemIndex(nextIndex);
            }, 500);
        }
    };

    const [isSeeModalOpen, setIsSeeModalOpen] = useState(false);

    const handleSeeModalOpenClick = (isOpen) => {
        setIsSeeModalOpen(true);
    }

    const handleSeeModalCloseClick = (isOpen) => {
        setIsSeeModalOpen(false);
    }

    return (
        <div className="page lettres">
            <div className="timeline">
                <div className="timeline-line"></div>
                <div className="timeline-wrapper">
                    {userSpace ? userSpace.acf.documents.map((item, index) => (
                        <div
                            className={`timeline-item ${item.acf.expediteur.acf.genre} ${selectedItem && selectedItem.ID === item.ID ? 'timeline-item-selected' : ''}`}
                            key={item.ID} onClick={() => handleClick(item, index)}>
                            <div className="timeline-item-img">
                                <img src={item.acf.expediteur.acf.profil}/>
                            </div>
                            <h5>{item.title}</h5>
                        </div>
                    )) : ''}
                </div>
            </div>
            <div className={`from-to ${selectedItem && selectedItem.acf.expediteur.acf.genre === 'woman' ? 'bg-pink-50' : 'bg-blue-50'}`}>
                <div className="container">
                    <div className="from-to-wrapper">
                        <div className="from">
                            <h5>{selectedItem ? selectedItem.acf.expediteur.acf.prenom_nom : ''}</h5>
                        </div>
                        <div className="transit">
                            <img src={iconFrom} alt="" className="imgFrom"/>
                            <div className="transit bg-black"></div>
                            <img src={iconTo} alt="" className="imgTo"/>
                        </div>
                        <div className="from">
                            <h5>{selectedItem ? selectedItem.acf.destinataire.acf.prenom_nom : ''}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div id="lettre-info" className={`${selectedItem && selectedItem.acf.expediteur.acf.genre === 'woman' ? 'bg-pink' : 'bg-blue'}`} ref={lettreInfo}>
                <div className="container">
                    <div className="lettre-info">
                        <div className={`timeline-item timeline-item-big timeline-item-selected`}>
                            <div className="timeline-item-img">
                                <img src={selectedItem ? selectedItem.acf.expediteur.acf.profil : ''} alt={`Profile of ${selectedItem ? selectedItem.acf.expediteur.acf.title : ''}`}/>
                            </div>
                        </div>
                        <div className="lettre-info-title">
                            <h4>{selectedItem ? selectedItem.acf.date : ''}</h4>
                            <h1>{selectedItem ? selectedItem.post_title : ''}</h1>
                        </div>
                    </div>
                    <div className="lettre-action">
                        <div className="lettre-action-player">
                            <H5AudioPlayer
                                src={selectedItem ? selectedItem.acf.audio.url : ''}
                                onPlay={e => console.log("Playing")}
                                onEnded={e => console.log("Audio ended")}
                                customAdditionalControls={[]}
                                showJumpControls={false}
                                layout="horizontal-reverse"
                                customProgressBarSection={[]}
                                customControlsSection={["MAIN_CONTROLS", "PROGRESS_BAR", "CURRENT_TIME", "DURATION"]}

                                customIcons={{
                                    play: <img src={iconPlay}/>,
                                    pause: <img src={iconPause}/>
                                }}
                                autoPlay={false}
                                autoPlayAfterSrcChange={false}
                            />
                        </div>
                        <div className="lettre-action-viewer">
                            <button className="btn-link" onClick={handleSeeModalOpenClick}>
                                <img src={iconVoir} alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container lettre-contenu" dangerouslySetInnerHTML={{__html: selectedItem ? selectedItem.acf.transcript : ''}}></div>

            {isSeeModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <Swiper
                            loop={false}
                            modules={[Navigation]}
                            navigation={{
                                prevEl: prevRef.current,
                                nextEl: nextRef.current,
                            }}
                            onBeforeInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                            }}
                        >
                            {selectedItem.acf.images.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <img src={image.url} alt={`Slide ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="modal-actions">
                        <button type="button" ref={prevRef} className="modal-action prev">
                            <svg fill="#000000" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier"><path d="M656 852L294 535q-16-14-16-35t16-35l362-317q13-10 28-9t26 11.5 11 27.5v644q0 17-11 27.5T684 861t-28-9z"></path></g>
                            </svg>
                        </button>
                        <button type="button" className="modal-action" onClick={handleSeeModalCloseClick}>
                            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path d="M7.17158 14.2426C6.78106 14.6332 6.14789 14.6332 5.75737 14.2426C5.36684 13.8521 5.36684 13.2189 5.75737 12.8284L12.8284 5.75735C13.219 5.36682 13.8521 5.36682 14.2427 5.75735C14.6332 6.14787 14.6332 6.78104 14.2427 7.17156L7.17158 14.2426Z" fill="#000000"></path>
                                    <path d="M5.75737 7.17152C5.36684 6.781 5.36684 6.14783 5.75737 5.75731C6.14789 5.36679 6.78106 5.36679 7.17158 5.75731L14.2427 12.8284C14.6332 13.2189 14.6332 13.8521 14.2427 14.2426C13.8521 14.6331 13.219 14.6331 12.8284 14.2426L5.75737 7.17152Z" fill="#000000"></path>
                                </g>
                            </svg>
                        </button>
                        <button type="button" ref={nextRef} className="modal-action next">
                            <svg fill="#000000" viewBox="0 0 24 24" id="next" data-name="Line Color"
                                 xmlns="http://www.w3.org/2000/svg" className="icon line-color">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path id="primary" d="M17,12,5,21V3Z"></path>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
            )}

            <div className="lettre-suivante text-center">
                <button className="btn" onClick={() => handleNextClick()}>
                    Lettre suivante
                </button>
            </div>
        </div>
    );
}

export default Lettres;
