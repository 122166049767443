import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://api.bonjourlafamille.fr/wp-json',
    headers: {
        'Content-Type': 'application/json',
    },
});

apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

apiClient.interceptors.response.use((response) => response,
    async error => {
        if (error.response) {
            if (error.response.status === 401) {
                const refreshToken = localStorage.getItem('authToken');
                if (refreshToken) {
                    try {
                        const { data } = await apiClient.post('/jwt-auth/v1/token/refresh', { refreshToken });
                        localStorage.setItem('authToken', data.token);
                        error.config.headers['Authorization'] = `Bearer ${data.token}`;
                        return apiClient.request(error.config);
                    } catch (refreshError) {
                        localStorage.removeItem('authToken');
                        window.location.href = '/connexion';
                    }
                } else {
                    localStorage.removeItem('authToken');
                    window.location.href = '/connexion';
                }
            }
            else if (error.response.status === 403) {
                localStorage.removeItem('authToken');
                window.location.href = '/connexion';
            }
        }
        return Promise.reject(error);
    }
);

const apiService = {
    login: async (username, password) => {
        try {
            const response = await apiClient.post('/jwt-auth/v1/token', { username, password });
            return response.data;
        } catch (error) {
            throw error.response ? error.response.data : new Error('Erreur de connexion');
        }
    },
    validateToken: async (token) => {
        try {
            const response = await apiClient.post('/jwt-auth/v1/token/validate', {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            throw error.response ? error.response.data : new Error('Erreur lors de la validation du token');
        }
    },
    getSpaces: async () => {
        try {
            const response = await apiClient.get('/wp/v2/blf-espace');
            return response.data;
        } catch (error) {
            throw error.response ? error.response.data : new Error('Erreur lors de la récupération des posts');
        }
    },
    getDocuments: async () => {
        try {
            const response = await apiClient.get('/wp/v2/blf_document');
            return response.data;
        } catch (error) {
            throw error.response ? error.response.data : new Error('Erreur lors de la récupération des posts');
        }
    },
};

export default apiService;
